var sliders = [];

/****************************************************************************************************************************************************************************************
DOCUMENT READY
****************************************************************************************************************************************************************************************/

jQuery(document).ready(function($){

/**********************************************
VARIOUS
**********************************************/

	// Gmap
	if ($.isFunction(window.initGmap)) {
		if ($('.gmap').length) {
			initGmap('gmap'); // element id
		}
	}

	// Scrollování ke kotvě
	$('.to-anchor').scrollToAnchor();
	$('.fix-nav a').scrollToAnchor();

	// Show/hide
	$('.sh-trig').toggler();

	// Show/hide marker
	$('.sh-trig-marker').toggler({
		hideOnEscape: true,
		hideOnDocumentClick: true,
		documentClickElement: $('.marker-content')
	});

	// Scrollspy
	$('.scrollspy').scrollSpy();

	// Faq & instructions
	$('.faq .item, .instructions .item').each(function() {
		var item = $(this);
		var head = item.find('.item-head');

		head.click(function() {
			if( ! item.hasClass('active') ) {
				item.addClass('active');
			}
			else {
				item.removeClass('active');
			}
		});
	});

	// Flash messages
	$('body').delegate('.flash-message .close','click',function() {
		$(this).closest('.flash-message').hide();
	});

	/**********************************************
	FIXER
	**********************************************/

	$(window).on('load scroll',function() {
		if ( $('.fixer').length ) {
			$('.fixer-start').each(function() {
				var scrollTop = $(window).scrollTop();
				var winH = $(window).height();
				var fixElWrapper = $(this);
				var fixEl = fixElWrapper.find('.fixer');
				var offset = 0;
				if ( fixEl.attr('data-offset') ) {
					var offset = fixEl.data('offset');
				}

				var fixElWrapperTop = fixElWrapper.offset().top + offset;

				var fixElHeight = fixEl.height();
				var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

				// Top panel
				if ( scrollTop > fixElWrapperTop ) {
					fixEl.addClass('fixed');
					// Hide in the end
					if ( scrollTop > end ) {
						fixEl.fadeOut();
					}
					else {
						fixEl.fadeIn();
					}
				}
				else {
					fixEl.removeClass('fixed');
				}
			});
		}
	});

	/**********************************************
	MOBILE NAV
	**********************************************/

	$(function() {
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');
		var btn = $('.nav-btn');
		var header = $('.header');

		function showNav() {
			mobileNav.addClass('active');
			mobileNavOverlay.fadeIn();
			btn.addClass('active');
			header.addClass('active-nav');
			$('body').css({ 'overflow': 'hidden' });
		}

		function hideNav() {
			mobileNav.removeClass('active');
			mobileNavOverlay.fadeOut();
			btn.removeClass('active');
			header.removeClass('active-nav');
			$('body').css({ 'overflow': 'auto' });
		}

		btn.click(function() {
			if ( ! mobileNav.hasClass('active') ) {
				showNav();
			}
			else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').click(function(){
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').click(function(){
			hideNav();
		});

		$('.mobile-nav li a').each(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav li .more').click(function() {
			var link = $(this).closest('a');
			var ul = link.siblings('ul');

			if ( ul.length > 0 ) {
				if ( ! ul.hasClass('active') ) {
					link.addClass('active');
					ul.addClass('active');
				} else {
					link.removeClass('active');
					ul.removeClass('active');
				}
				return false;
			}
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function() {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function() {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function() {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function() {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
	FANCYBOX
	**********************************************/

	if ($.fn.fancybox) {

		var fancyboxOptions = {
			//width: 600,
			//height: 230,
			padding: 0,
			autoSize: true,
			autoHeight: true,
			autoWidth: true,
			fitToView: true,
			openSpeed: 250,
			closeSpeed: 150,
			tpl: {
				wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>'
			}
		}

		// Standard fancybox
		$(".fancybox, a[href$='.jpg'],a[href$='.jpeg'],a[href$='.png'],a[href$='.gif'],[data-fancybox='gallery']").fancybox(fancyboxOptions);

		// Modal box
		$('.modal-trigger').fancybox(fancyboxOptions);

		// Modal box repositioning
		$('.modal-reposition').on('change', function(){
			$.fancybox.reposition();
		});

		// Modal box after ajax load
		$(window).on('load filterLoad',function() {
			$('#form-ajax-content .modal-trigger').fancybox(fancyboxOptions);
		});
	}

	/**********************************************
	SLIDERS
	**********************************************/

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumTablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
			el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
			el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;
			el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';
			el.attr('data-customlink') ? sliders[id]['slideCustomlink'] = el.data('customlink') : sliders[id]['slideCustomlink'] = 'a.customLink';

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					effect: sliders[id]['slideEffect'],
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: sliders[id]['slideContinuous'],
					prevNext: sliders[id]['slideNav'],
					numeric: sliders[id]['slideNumeric'],
					slideCount: sliders[id]['slideNum'],
					speed: sliders[id]['slideSpeed'],
					responsive: true,
					customLink: sliders[id]['slideCustomlink'],
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});

		$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var width = $(window).width();

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;

				if (width >= 1200) {
					slideCount = sliders[i]['slideNum']; // 1200 - 1920
				}
				else if (width >= 992) {
					slideCount = sliders[i]['slideNumDesktop']; // 992 - 1199
				}
				else if (width >= 768) {
					slideCount = sliders[i]['slideNumTablet']; // 768 - 991
				}
				else {
					slideCount = sliders[i]['slideNumMobile']; // 0 - 767
				}
				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			};
		}).resize();
	}

});

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Scrollspy
(function($) {
	$.fn.scrollSpy = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			callback: function() {}
		}, settings);

		var topMenu = $(this);

		if ( topMenu.length ) {
			// Cache selectors
			var lastId,
				topMenuHeight = topMenu.outerHeight(),

				// All list items
				menuItems = topMenu.find("a"),

				// Anchors corresponding to menu items
				scrollItems = menuItems.map(function(){
					var item = $( $(this).attr("href") );
					if (item.length) { return item; }
				});

			// Bind to scroll
			$(window).bind('load scroll', function(){
				// Get container scroll position
				var fromTop = $(this).scrollTop() + topMenuHeight;

				// Get id of current scroll item
				var cur = scrollItems.map(function(){
					if ($(this).offset().top < fromTop)
					return this;
				});
				// Get the id of the current element
				cur = cur[cur.length-1];
				var id = cur && cur.length ? cur[0].id : "";

				if (lastId !== id) {
					lastId = id;
					// Set/remove active class
					menuItems
					.parent().removeClass("active")
					.end().filter("[href*=\\#" + id + "]").parent().addClass("active");
				}
			});
		}

	}
})(jQuery);

// Univerzální show/hide
(function($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false
		}, settings);

		$(this).each(function(index,value) {

			var trigger = $(this);
			var content = $('#' + trigger.data('content'));

			// Radio nebo checkbox
			if ( trigger.is(':radio') || trigger.is(':checkbox') ) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function() {
					content.hide();
				});
				trigger.on('change', function() {
					if ( $(this).is(':checked') ) {
						content.fadeIn(settings.showSpeed);
					}
					else {
						content.hide();
					}
				});
				$(window).on('load',function() {
					if ( trigger.is(':checked') ) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				function scrollToContent() {
					var offset = 0;
					if ( settings.offsetScroll ) {
						offset = settings.offsetScroll
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $(content).offset().top + offset }, 500);
				}

				function scrollToTrigger() {
					var offset = 0;
					if ( settings.offsetScroll ) {
						offset = settings.offsetScroll + 200
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset }, 500);
				}

				trigger.on('click',function(event) {
					if ( content.is(':hidden') ) {
						if ( settings.slide ) {
							content.slideDown(settings.showSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToContent();
								};
							});
						}
						else {
							content.fadeIn(settings.showSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToContent();
								};
							});
						}

						$(this).addClass('active').find('.text').text( $(this).attr('data-less') );

						if ( settings.hideTrigger ) {
							trigger.hide();
						}
					}
					else {
						if ( settings.slide ) {
							content.slideUp(settings.hideSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToTrigger();
								};
							});
						}
						else {
							content.fadeOut(settings.hideSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToTrigger();
								};
							});
						}
						$(this).removeClass('active').find('.text').text( $(this).attr('data-more') );

						if ( settings.hideTrigger ) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					event.preventDefault();
					//return false;
				});
			}
			// Hide on document click
			if ( settings.hideOnDocumentClick || trigger.attr('data-hideonclick') == 'true' ) {
				$(document).on('click', function(e) {
					if ( (! $(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) && ( ! trigger.is(e.target) && trigger.has(e.target).length === 0 ) )  {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
			// Escape button
			if ( settings.hideOnEscape ) {
				$(document).keyup(function(e){
					if (e.keyCode === 27) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
		});
	}
})(jQuery);

// Plynulý scroll ke kotvě
(function($) {
	$.fn.scrollToAnchor = function(settings) {

		settings = jQuery.extend({
			speed : 750
		}, settings);

		return this.each(function(){
			var caller = this
			$(caller).click(function (event) {
				event.preventDefault()
				var locationHref = window.location.href;
				var elementClick = $(caller).attr("href");
				var destination = $(elementClick).offset().top;

				$("html:not(:animated),body:not(:animated)").animate({
						scrollTop: destination
					}, {
					  	duration: settings.speed,
					  	easing: "easeInOutQuint",
					  	complete: function() {
							history.pushState(null, null, elementClick);
						}
					}
				);
				return false;
			});
		});
	}
})(jQuery);