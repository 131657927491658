//OpenLayers
jQuery(function(){
	if($('#olmap').length) {
		var map = $('#olmap');

		var args = {
			zoom: 13,
			lat: map.data('lat'),
			lng: map.data('lng'),
			markerIcon: 'static/images/marker.png',
			centermarkerIcon: true, //set true if center of the marker should be centered to point
			// grayScale: true,
			scrollWheel: false
		};

		if(args.lat && args.lng) {
			//create open street map layer
			var osmLayer = new ol.layer.Tile({
				source: new ol.source.OSM()
			});

			//apply grayscale
			if(args.grayScale) {
				osmLayer.on('postcompose', function(event) {
					greyscale(event.context);
			   });
			}

			//create map
			var map = new ol.Map({
				target: 'olmap',
				layers: [
				  osmLayer
				],
				view: new ol.View({
					center: ol.proj.fromLonLat([args.lng, args.lat]),
					zoom: args.zoom
				}),
				controls: ol.control.defaults({
					rotate: false,
					attribution: false
				}),
				interactions: ol.interaction.defaults({mouseWheelZoom: args.scrollWheel}),
			});

			//create marker and set marker icon
			var marker = new ol.Feature({
				geometry: new ol.geom.Point(
				  ol.proj.fromLonLat([args.lng, args.lat])
				),
			});

			if(args.markerIcon) {
				var markerStyle = {
					crossOrigin: 'anonymous',
					src: '/static/images/marker.png'
				};
				if(!args.centermarkerIcon) {
					markerStyle.anchor = [0.5, 1];
					markerStyle.anchorXUnits = 'fraction';
					markerStyle.anchorYUnits = 'fraction';
				}
				marker.setStyle(new ol.style.Style({
					image: new ol.style.Icon((markerStyle))
				}));
			}

			var vectorSource = new ol.source.Vector({
				features: [marker]
			});

			var markerVectorLayer = new ol.layer.Vector({
				source: vectorSource,
			});
			map.addLayer(markerVectorLayer);
		}
	}
});

//grayscale to every pixel in canvas
function greyscale(context) {

	var canvas = context.canvas;
	var width = canvas.width;
	var height = canvas.height;

	var imageData = context.getImageData(0, 0, width, height);
	var data = imageData.data;

	for (i = 0; i < data.length; i += 4){
		var r = data[i];
		var g = data[i + 1];
		var b = data[i + 2];
		// CIE luminance for the RGB
		var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
		// Show white color instead of black color while loading new tiles:
		if(v === 0.0) v=255.0;
		data[i+0] = v; // Red
		data[i+1] = v; // Green
		data[i+2] = v; // Blue
		data[i+3] = 255; // Alpha
	}
	context.putImageData(imageData,0,0);
}
