var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Gallery slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = 1;
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = 1;
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.attr('data-auto') : sliders[id]['slideAuto'] = true;

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 3,
					speed: 250,
					responsive: true,
					customLink: 'a.customLink',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});

		$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var width = $(window).width();

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;
				if (width >= 1200) {
					slideCount = sliders[i]['slideNum'];
				} else if (width > 752) { // = 768 media queries
					slideCount = sliders[i]['slideNumTablet'];

				} else {
					slideCount = sliders[i]['slideNumMobile'];
				}

				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			}
			;
		}).resize();

		// Teaser slider
		$('.teaser-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				teaserSlider = $(this).find('.slider').sudoSlider({
					effect: 'slide',
					vertical: false,
					auto: true,
					autowidth: false,
					continuous: true,
					prevNext: false,
					numeric: false,
					// slideCount: 1,
					speed: 1000,
					responsive: true,
					//customLink: '.room-slider .switch a',
					// controlsAttr: 'class="slider-nav"',
					// prevHtml: '<a href="#" class="prev"></a>',
					// nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});
	}

});
