$('.milestones .item').each(function(i) {
	var item = $(this);
	if (i > 1) {
		var hItem = item.position().top;
		var itemPrev = item.prev().prev();
		var hItemPrev = itemPrev.outerHeight();
	}
	$(window).on('load scroll',function() {
		if (inViewport(item)) {
			item.addClass('active');
		}
	});
});

function inViewport(el) {
	var elH = $(el).height();
	var elTop = $(el).offset().top;
	var scrollTop = $(window).scrollTop();
	var winH = $(window).height();
	fixedHeaderH = 0;
	return ((elTop + elH) > (scrollTop + fixedHeaderH ) && (scrollTop - elH) > (elTop - winH));
}
